import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CommunityComponentModel} from 'community-builder-component-lib';

declare global {
  interface Window {
    displayName?:string;
    currentLanguage?: string;
    defaultLanguage?: string;
    initComponentHeaderData? : string;
    initComponentPropsBodyData? : CommunityComponentModel;
  }
}

export const initComponentPropsBodyData = window?.initComponentPropsBodyData;

const root = ReactDOM.createRoot(
  document.getElementById('community-ui-main') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App communityModel = {initComponentPropsBodyData}/>
  </React.StrictMode>
);
