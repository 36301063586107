import React from 'react';
import { CommunityPageAdminModel, CommunityComponentModel} from 'community-builder-component-lib';
import './index.css';
import './App.css';

export interface AppProps {
  communityModel?: CommunityComponentModel;
}

const App = (props: AppProps) => {
  const cam = props.communityModel?.data as CommunityPageAdminModel;
  const id = cam.id ?? 'new';
  const controlData =  (cam?.communityControls && cam?.communityControls.length > 0) ? 
    cam?.communityControls[0].controlData : '';

  return (
    <div className="ck-content apricus-content" dangerouslySetInnerHTML={{__html: controlData ?? ""}}></div>
  );
}

export default App;
